var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600","persistent":_vm.loading,"scrollable":"","eager":""},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"h-full d-flex flex-column",staticStyle:{"max-height":"90dvh"}},[_c('v-card-title',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"text-16 lh-1"},[_vm._v(" Editar bilheteria ")])]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Nome","rules":[(v) => !!v.length || 'Nome é obrigatório'],"outlined":""},model:{value:(_vm.ticketOffice.name),callback:function ($$v) {_vm.$set(_vm.ticketOffice, "name", $$v)},expression:"ticketOffice.name"}}),_c('div',{staticClass:"d-flex gap-4 flex-wrap"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Dinheiro","value":"money","hide-details":"","rules":[
              (v) => !!v.length || 'Método de pagamento é obrigatório',
            ]},model:{value:(_vm.ticketOffice.allowPaymentMethods),callback:function ($$v) {_vm.$set(_vm.ticketOffice, "allowPaymentMethods", $$v)},expression:"ticketOffice.allowPaymentMethods"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"PIX","value":"pix","hide-details":"","rules":[
              (v) => !!v.length || 'Método de pagamento é obrigatório',
            ]},model:{value:(_vm.ticketOffice.allowPaymentMethods),callback:function ($$v) {_vm.$set(_vm.ticketOffice, "allowPaymentMethods", $$v)},expression:"ticketOffice.allowPaymentMethods"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Cartão de Crédito","value":"credit","hide-details":"","rules":[
              (v) => !!v.length || 'Método de pagamento é obrigatório',
            ]},model:{value:(_vm.ticketOffice.allowPaymentMethods),callback:function ($$v) {_vm.$set(_vm.ticketOffice, "allowPaymentMethods", $$v)},expression:"ticketOffice.allowPaymentMethods"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Cartão de Débito","value":"debit","hide-details":"","rules":[
              (v) => !!v.length || 'Método de pagamento é obrigatório',
            ]},model:{value:(_vm.ticketOffice.allowPaymentMethods),callback:function ($$v) {_vm.$set(_vm.ticketOffice, "allowPaymentMethods", $$v)},expression:"ticketOffice.allowPaymentMethods"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Cortesia","value":"courtesy","hide-details":"","rules":[
              (v) => !!v.length || 'Método de pagamento é obrigatório',
            ]},model:{value:(_vm.ticketOffice.allowPaymentMethods),callback:function ($$v) {_vm.$set(_vm.ticketOffice, "allowPaymentMethods", $$v)},expression:"ticketOffice.allowPaymentMethods"}})],1)],1),(_vm.error)?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1),_c('ticket-office-adopt-modal',{ref:"adoptModal",attrs:{"party":_vm.party},on:{"success":_vm.success}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }