<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>Cancelar Evento</v-card-title>
        <v-card-text v-if="!success">
          <v-form v-model="valid" @submit.prevent>
            <v-textarea
              v-model="reason"
              label="Motivo da cancelamento"
              placeholder="Devido a..."
              rows="5"
              outlined
              :disabled="loading"
              hint="Esse motivo será exibido para os clientes e enviado por e-mail."
              persistent-hint
              :rules="[
                (v) => !!v || 'Motivo da cancelamento é obrigatório',
                (v) => v.length > 50 || 'Explique o motivo da cancelamento',
              ]"
            />
            <v-alert color="info" text class="mb-0" @click="previewEmail">
              O motivo da cancelamento será enviado para os clientes via e-mail.
              <small> Clique aqui para pré-visualizar o e-mail. </small>
            </v-alert>
            <v-checkbox
              v-model="dubleConfirm"
              :label="`Entendo que todos os ingressos vendidos serão reembolsados e os clientes serão notificados via e-mail.`"
              :rules="[(v) => !!v || 'Você deve confirmar a ação']"
              hide-details
            />
            <v-checkbox
              v-model="confirm"
              :label="`Confirmo que desejo cancelar o evento ${party.name}`"
              :rules="[(v) => !!v || 'Você deve confirmar a ação']"
              hide-details
            />
            <v-alert v-if="confirm" type="warning" class="mt-4 mb-0">
              Essa ação é irreversível!
            </v-alert>
            <v-alert v-if="error" type="error" class="mt-4 mb-0">
              {{ error }}
            </v-alert>
          </v-form>
        </v-card-text>
        <v-card-text v-if="success" class="d-flex flex-column align-center">
          <v-icon size="80">mdi-calendar-remove</v-icon>
          <h5 class="mb-2 mt-4">Evento cancelado com sucesso!</h5>
          <p class="mb-0">
            Será enviado e-mail para
            <strong>{{ totalUsers }} usuários</strong> e
            <strong>{{ totalTickets }} ingressos</strong> serão reembolsados.
          </p>
        </v-card-text>
        <v-card-actions v-if="!success">
          <v-btn text :disabled="loading" @click="close"> Não, cancelar </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            :disabled="!valid || loading"
            :loading="loading"
            @click="cancelParty"
          >
            Cancelar Evento
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="success" class="justify-center">
          <v-btn text block @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CancelEmailPreview :party="party" :reason="reason" />
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import CancelEmailPreview from "./CancelEmailPreview.vue";

export default {
  components: {
    CancelEmailPreview,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      reason: "",
      confirm: false,
      dubleConfirm: false,
      success: false,
      error: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.success = false;
      this.error = false;
      this.confirm = false;
    },
    close() {
      this.dialog = false;
      this.success = false;
      this.confirm = false;
    },
    previewEmail() {
      this.$emit("cancel-party-mail-preview");
    },
    async cancelParty() {
      try {
        this.loading = true;
        this.error = false;
        const confirm = await window.confirm(
          "Tem certeza que deseja cancelar o evento?\nEssa ação é irreversível!"
        );
        if (!confirm) return;

        await PARTY.cancel(this.party.Organization.id, this.party.id, {
          reason: this.reason,
        });
        this.success = true;
        this.$emit("refresh");
      } catch (error) {
        this.error = error.message || "Ocorreu um erro ao cancelar o evento";
        this.confirm = false;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$root.$on("cancel-party", this.open);
  },
};
</script>
