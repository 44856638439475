<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    overlay-opacity="0.9"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="flex-grow-1">Detalhes por Dia</span>
        <div class="d-flex align-center flex-grow-1">
          <v-btn @click="changeDate(-1)" icon :disabled="isLastDay">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="currentDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text>
                <b class="text-14"> {{ currentDate | date("DD/MM/YYYY") }} </b>
              </v-btn>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :max="maxDate"
              :min="firstPaymentDate"
              class="rounded-lg"
            >
              <v-btn text @click="menu = false"> Cancelar </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$refs.menu.save(date)">
                Selecionar
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer />
          <v-btn @click="changeDate(1)" :disabled="isToday" icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column gap-3">
          <v-tabs v-model="tab" grow>
            <v-tab> Geral </v-tab>
            <v-tab> Por PDV </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div v-if="ticketsFromDay.length === 0">
                <v-alert type="info" class="mb-0" text>
                  Nenhum ingresso vendido nesse dia
                </v-alert>
              </div>
              <div v-else class="d-flex flex-column gap-3">
                <!-- Chart -->
                <v-card outlined>
                  <div class="d-flex justify-end px-3 pt-3">
                    <v-btn-toggle mandatory v-model="display" class="ml-2">
                      <v-btn small> R$ </v-btn>
                      <v-btn small> Qtde </v-btn>
                    </v-btn-toggle>
                  </div>
                  <v-row class="mx-0" dense>
                    <v-col cols="12" md="6">
                      <b class="pl-3">Vendas por canal</b>
                      <apexCharts
                        type="donut"
                        height="250"
                        :options="byChannelOptions"
                        :series="byChannelSeries"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <b class="pl-3">Vendas por hora</b>
                      <apexCharts
                        type="line"
                        height="250"
                        :options="salesByHourOptions"
                        :series="salesByHourSeries"
                      />
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Totals -->
                <v-card
                  outlined
                  class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3 pa-3"
                >
                  <div class="text-center">
                    <p class="mb-0 lh-1">Venda</p>
                    <p class="mb-0 font-weight-bold">
                      {{ totalTicketGroups.sale }}
                      ingresso{{ totalTicketGroups.sale == 1 ? "" : "s" }}
                    </p>
                  </div>
                  <div class="text-center">
                    <p class="mb-0 lh-1">Cortesia</p>
                    <p class="mb-0 font-weight-bold">
                      {{ totalTicketGroups.courtesy }}
                      ingresso{{ totalTicketGroups.courtesy == 1 ? "" : "s" }}
                    </p>
                  </div>
                  <div class="text-center">
                    <p class="mb-0 lh-1">Total</p>
                    <p class="mb-0 font-weight-bold">
                      {{ totalTicketGroups.count }}
                      ingresso{{ totalTicketGroups.count == 1 ? "" : "s" }}
                    </p>
                  </div>
                  <div class="text-center">
                    <p class="mb-0 lh-1">Valor Total</p>
                    <p class="mb-0 font-weight-bold">
                      {{ totalTicketGroups.value | currency }}
                    </p>
                  </div>
                </v-card>

                <!-- Setores -->
                <v-card
                  v-for="group in ticketGroups"
                  :key="group.id"
                  outlined
                  class="pa-3"
                >
                  <b>{{ group.name }}</b>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left w-full">Lote</th>
                          <th class="text-right">Valor</th>
                          <th class="text-center">Venda</th>
                          <th class="text-center">Cortesia</th>
                          <th class="text-right">Total</th>
                          <th class="text-right">Valor Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="block in group.TicketBlock"
                          :key="block.id"
                          style="border-top: 1px solid red !important"
                        >
                          <td class="font-weight-medium w-full">
                            {{ block.name }}
                          </td>
                          <td class="text-right">
                            {{ block.originalPrice | currency }}
                          </td>
                          <td class="text-center">{{ block.sale }}</td>
                          <td class="text-center">{{ block.courtesy }}</td>
                          <td class="text-center">{{ block.count }}</td>
                          <td class="text-right">
                            {{ block.value | currency }}
                          </td>
                        </tr>
                        <tr
                          class="font-weight-bold"
                          style="border-top: 2px solid #000 !important"
                        >
                          <td class="font-weight-bold text-end text-15 w-full">
                            Total
                          </td>
                          <td></td>
                          <td class="text-center text-15">
                            {{ group.total.sale }}
                          </td>
                          <td class="text-center text-15">
                            {{ group.total.courtesy }}
                          </td>
                          <td class="text-center text-15">
                            {{ group.total.count }}
                          </td>
                          <td class="text-right text-15">
                            {{ group.total.value | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </v-tab-item>

            <!-- POS -->
            <v-tab-item>
              <div v-if="ticketsFromDay.length === 0">
                <v-alert type="info" class="mb-0" text>
                  Nenhum ingresso vendido nesse dia
                </v-alert>
              </div>
              <div v-else class="d-flex flex-column gap-3">
                <v-alert v-if="pos.length === 0" type="info" class="mb-0" text>
                  Nenhum venda em PDV nesse dia
                </v-alert>
                <template v-else>
                  <!-- Chart -->
                  <v-card outlined>
                    <div class="d-flex justify-end px-3 pt-3">
                      <v-btn-toggle mandatory v-model="display" class="ml-2">
                        <v-btn small> R$ </v-btn>
                        <v-btn small> Qtde </v-btn>
                      </v-btn-toggle>
                    </div>
                    <v-row class="mx-0" dense>
                      <v-col cols="12" md="6">
                        <b class="pl-3">Vendas por forma de pagamento</b>
                        <apexCharts
                          type="donut"
                          height="250"
                          :options="posByPaymentTypeOptions"
                          :series="posByPaymentTypeSeries"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <b class="pl-3">Vendas por hora</b>
                        <apexCharts
                          type="line"
                          height="250"
                          :options="salesByHourOptions"
                          :series="salesByHourSeries"
                        />
                      </v-col>
                    </v-row>
                  </v-card>

                  <!-- Totals -->
                  <v-card
                    outlined
                    class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3 pa-3"
                  >
                    <div class="text-center">
                      <p class="mb-0 lh-1">Total</p>
                      <p class="mb-0 font-weight-bold">
                        {{ totalPos.qnt }}
                        ingresso{{ totalTicketGroups.sale == 1 ? "" : "s" }}
                      </p>
                    </div>
                    <div class="text-center">
                      <p class="mb-0 lh-1">Valor Total</p>
                      <p class="mb-0 font-weight-bold">
                        {{ totalPos.totalValue | currency }}
                      </p>
                    </div>
                  </v-card>

                  <v-card v-for="p in pos" :key="p.id" class="pa-3" outlined>
                    <b>
                      <v-avatar v-if="!p.active" color="warning" size="16">
                        <v-icon x-small color="white">mdi-connection</v-icon>
                      </v-avatar>
                      {{ p.name || p.Address?.name }}
                    </b>
                    <div class="d-flex flex-column gap-2 mt-1">
                      <v-card outlined>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left w-full">
                                  Forma de Pagamento
                                </th>
                                <th class="text-center">Qtde</th>
                                <th
                                  class="text-right"
                                  style="max-width: 100px !important"
                                >
                                  Valor
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="pt in p.paymentTypes"
                                :key="pt.paymentType"
                              >
                                <td class="w-full">
                                  {{ payment.paymentType[pt.paymentType].text }}
                                </td>
                                <td class="text-center">{{ pt.qnt }}</td>
                                <td class="text-right">
                                  {{ pt.totalValue | currency }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>

                      <v-card outlined>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left w-full">Lote</th>
                                <th class="text-center">Qtde</th>
                                <th class="text-right">Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="tb in p.ticketBlocks" :key="tb.id">
                                <td class="w-full">
                                  {{ tb.TicketGroup?.name }}
                                  <small class="d-block">{{ tb.name }}</small>
                                </td>

                                <td class="text-center">{{ tb.qnt }}</td>
                                <td class="text-right">
                                  {{ tb.totalValue | currency }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>

                      <div class="d-flex justify-end gap-2">
                        <b>Total:</b>
                        <span class="text-14">
                          {{ p.qnt }} ingresso{{ p.qnt == 1 ? "" : "s" }}
                          •
                          {{ p.totalValue | currency }}
                        </span>
                      </div>
                    </div>
                  </v-card>
                </template>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn block text @click="dialog = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ApexCharts from "vue-apexcharts";
import payment from "../../../../../utils/payment";

export default {
  components: { ApexCharts },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    payment,
    dialog: false,
    currentDate: new Date(),
    tab: 0,
    display: 0,
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    maxDate: moment().format("YYYY-MM-DD"),

    channelTranslation: {
      pos: {
        text: "PDV",
        color: "#00e396",
      },
      ticketOffice: {
        text: "Bilheteria",
        color: "#f44336",
      },
      shop: {
        text: "Site",
        color: "#2196f3",
      },
      seller: {
        text: "Promoter",
        color: "#ff9800",
      },
    },
  }),
  methods: {
    changeDate(days) {
      this.currentDate = moment(this.currentDate).add(days, "days").toDate();
    },
    open(date) {
      if (date) this.currentDate = moment(date).local().toDate();
      this.dialog = true;
    },
  },
  computed: {
    paymentsFromDay() {
      if (!this.dialog) return [];
      const targetDate = moment(this.currentDate).startOf("day");

      return this.data.raw.payments.filter((p) => {
        if (p.status !== "succeeded") return false;
        const paymentDate = moment(p.payedAt || p.createdAt).startOf("day");
        return paymentDate.isSame(targetDate, "day");
      });
    },
    ticketsFromDay() {
      if (!this.dialog) return [];
      const targetDate = moment(this.currentDate).startOf("day");
      return this.data.raw.tickets.filter((t) => {
        if (t.Payment.status !== "succeeded") return false;
        const paymentDate = moment(
          t.Payment.payedAt || t.Payment.createdAt
        ).startOf("day");
        return paymentDate.isSame(targetDate, "day");
      });
    },
    ticketBlocksMap() {
      return this.data.ticketGroups.reduce((acc, g) => {
        g.TicketBlock.forEach((b) => {
          acc[b.id] = b;
        });
        return acc;
      }, {});
    },
    ticketGroups() {
      return Object.values(
        this.ticketsFromDay.reduce((acc, t) => {
          const block = t.TicketBlock;
          const group = block.TicketGroup;
          const isCourtesy = t.Payment.paymentType === "COURTESY";
          if (!acc[group.id])
            acc[group.id] = {
              name: group.name,
              id: group.id,
              TicketBlock: {},
              total: {
                count: 0,
                sale: 0,
                value: 0,
                courtesy: 0,
              },
            };

          acc[group.id].total.count++;
          acc[group.id].total.value += t.amount - t.platformFee;
          if (isCourtesy) acc[group.id].total.courtesy++;
          else acc[group.id].total.sale++;

          if (!acc[group.id].TicketBlock[block.id])
            acc[group.id].TicketBlock[block.id] = {
              originalPrice: this.ticketBlocksMap[block.id]?.price,
              name: block.name,
              count: 0,
              sale: 0,
              value: 0,
              courtesy: 0,
            };

          acc[group.id].TicketBlock[block.id].count++;
          acc[group.id].TicketBlock[block.id].value += t.amount - t.platformFee;
          if (isCourtesy) acc[group.id].TicketBlock[block.id].courtesy++;
          else acc[group.id].TicketBlock[block.id].sale++;

          return acc;
        }, {})
      )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group) => {
          group.TicketBlock = Object.values(group.TicketBlock).sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          return group;
        });
    },
    totalTicketGroups() {
      return this.ticketGroups.reduce(
        (acc, group) => {
          acc.count += group.total.count;
          acc.sale += group.total.sale;
          acc.courtesy += group.total.courtesy;
          acc.value += group.total.value;
          return acc;
        },
        { count: 0, sale: 0, courtesy: 0, value: 0 }
      );
    },
    totalPos() {
      return this.pos.reduce(
        (acc, p) => {
          acc.qnt += p.qnt;
          acc.totalValue += p.totalValue;
          return acc;
        },
        { qnt: 0, totalValue: 0 }
      );
    },
    pos() {
      const pos = this.paymentsFromDay.reduce((acc, payment) => {
        if (!payment.PosSession) return acc;

        const id = payment.PosSession.id;
        if (!acc[id]) {
          acc[id] = {
            ...payment.PosSession,
            qnt: 0,
            totalValue: 0,
            payments: 0,
            paymentTypes: {},
            ticketBlocks: {},
          };
        }

        acc[id].qnt += payment.Ticket.length;
        acc[id].totalValue += payment.amount - payment.platformFee;
        acc[id].payments++;

        const paymentType = payment.paymentType;
        if (!acc[id].paymentTypes[paymentType]) {
          acc[id].paymentTypes[paymentType] = {
            qnt: 0,
            totalValue: 0,
          };
        }
        acc[id].paymentTypes[paymentType].qnt += payment.Ticket.length;
        acc[id].paymentTypes[paymentType].totalValue +=
          payment.amount - payment.platformFee;

        payment.Ticket.forEach((ticket) => {
          const ticketBlock = ticket.TicketBlock;
          if (!acc[id].ticketBlocks[ticketBlock.id]) {
            acc[id].ticketBlocks[ticketBlock.id] = {
              ...ticketBlock,
              qnt: 0,
              totalValue: 0,
            };
          }
          acc[id].ticketBlocks[ticketBlock.id].qnt++;
          acc[id].ticketBlocks[ticketBlock.id].totalValue +=
            ticket.amount - ticket.platformFee;
        });

        return acc;
      }, {});

      return Object.values(pos).map((v) => ({
        ...v,
        paymentTypes: Object.entries(v.paymentTypes).map(
          ([paymentType, value]) => ({ ...value, paymentType })
        ),
        ticketBlocks: Object.values(v.ticketBlocks),
      }));
    },
    isToday() {
      return moment(this.currentDate).isSame(moment(), "day");
    },
    salesByHour() {
      return this.ticketsFromDay.reduce((acc, t) => {
        const p = t.Payment;
        let hour = moment(p.payedAt || p.createdAt).format("H");
        if (this.tab && !p.PosSession) return acc;

        if (!acc[hour]) acc[hour] = 0;
        acc[hour] += !this.display ? t.amount - t.platformFee : 1;
        return acc;
      }, {});
    },
    salesByHourSeries() {
      return [
        {
          name: "Ingressos vendidos",
          data: Array.from({ length: 24 }, (_, i) => this.salesByHour[i] || 0),
        },
      ];
    },
    salesByHourOptions() {
      return {
        chart: {
          type: "line",
          height: 250,
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        xaxis: {
          categories: Array.from({ length: 24 }, (_, i) => i + "h"),
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display
                ? `${value.toFixed(1).replace(".0", "")} ingresso${
                    value != 1 ? "s" : ""
                  }`
                : value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
          },
        },
      };
    },
    paymentTypes() {
      return this.ticketsFromDay.reduce((acc, t) => {
        const p = t.Payment;
        var channel = "shop";

        if (p.PosSession || p.paymentMethod == "POS") {
          channel = "pos";
          if (p.PosSession.partyId) channel = "ticketOffice";
        } else if (t.Seller) channel = "seller";

        if (!acc[channel]) {
          acc = { ...acc, [channel]: 0 };
        }
        acc[channel] += this.display ? 1 : t.amount - t.platformFee;

        return acc;
      }, {});
    },
    byChannelSeries() {
      if (!this.paymentTypes) return [];
      return Object.values(this.paymentTypes);
    },
    byChannelOptions() {
      const channelTranslation = this.channelTranslation;
      return {
        chart: { type: "donut", height: 250 },
        dataLabels: { enabled: false },
        labels: Object.keys(this.paymentTypes).map(
          (key) => channelTranslation[key].text || key
        ),
        colors: Object.keys(this.paymentTypes).map(
          (key) => channelTranslation[key].color || key
        ),
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display
                ? `${value.toFixed(1).replace(".0", "")} ingressos${
                    value != 1 ? "s" : ""
                  }`
                : value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
          },
        },
      };
    },
    posByPaymentType() {
      return this.paymentsFromDay.reduce((acc, p) => {
        if (!p.PosSession) return acc;
        if (!acc[p.paymentType]) acc[p.paymentType] = 0;
        acc[p.paymentType] += !this.display
          ? p.amount - p.platformFee
          : p.Ticket.length;
        return acc;
      }, {});
    },
    posByPaymentTypeSeries() {
      return Object.values(this.posByPaymentType);
    },
    posByPaymentTypeOptions() {
      return {
        chart: { type: "donut", height: 250 },
        dataLabels: { enabled: false },
        labels: Object.keys(this.posByPaymentType).map(
          (key) => this.payment.paymentType[key].text
        ),
        colors: Object.keys(this.posByPaymentType).map(
          (key) => this.payment.paymentType[key].color
        ),
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display
                ? `${value.toFixed(1).replace(".0", "")} ingresso${
                    value != 1 ? "s" : ""
                  }`
                : value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
          },
        },
      };
    },
    firstPayment() {
      return this.data.raw.payments.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )[0];
    },
    firstPaymentDate() {
      return this.firstPayment
        ? moment(this.firstPayment.createdAt).format("YYYY-MM-DD")
        : "";
    },
    isLastDay() {
      return moment(this.currentDate).isSame(
        moment(this.firstPaymentDate),
        "day"
      );
    },
  },
  watch: {
    currentDate() {
      this.date = moment(this.currentDate).format("YYYY-MM-DD");
    },
  },
};
</script>

<style></style>
