<template>
  <div class="mt-3">
    <h4>
      <v-icon left size="28">mdi-calendar-remove</v-icon>
      Evento cancelado em
      {{ party.cancelledAt | date("DD/MM/YYYY HH:mm") }}
    </h4>
    <p class="mb-0">{{ party.cancelledReason }}</p>

    <v-card
      v-if="party.CancelledBy"
      class="d-flex align-center pa-2 mt-3 rounded-lg gap-3"
      outlined
    >
      <base-avatar
        :src="party.CancelledBy?.photo"
        :seed="party.CancelledBy?.id"
        size="40"
      />
      <div>
        <span class="text-overline lh-1">Cancelado por</span>
        <b class="d-block mb-0">{{ party.CancelledBy?.name }}</b>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>
