<template>
  <div>
    <h5>Selecione o PDV</h5>
    <div class="d-flex flex-wrap gap-4 pb-3 justify-space-between align-start">
      <v-autocomplete
        dense
        outlined
        hide-details
        return-object
        item-value="id"
        :items="posSellers"
        v-model="filter.selectedPdv"
        placeholder="Filtrar"
        label="PDV"
      >
        <template v-slot:selection="{ index }">
          <div class="d-flex align-center" v-if="index === 0">
            {{ filter.selectedPdv?.Address?.name }}
          </div>
        </template>
        <template v-slot:item="{ item }">
          <v-icon>mdi-printer-pos</v-icon>
          {{ item.Address?.name }}
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    posSellers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["input", "isValid"],
  data: () => ({
    filter: {
      selectedPdv: null,
    },
  }),

  methods: {
    isValid() {
      if (!this.filter.selectedPdv) return false;
      return true;
    },
    formatFilter() {
      return {
        status: ["succeeded"],
        sellers: [this.filter.selectedPdv],
        courtesy: true,
      };
    },
  },
  computed: {},
  watch: {
    filter: {
      handler() {
        const isValid = this.isValid();
        this.$emit("isValid", isValid);
        if (isValid) this.$emit("input", this.formatFilter());
      },
      deep: true,
    },
  },
  mounted() {
    this.$emit("isValid", this.isValid());
  },
};
</script>

<style>
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 32px;
}
</style>
