<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card class="pt-5">
      <v-card-text>
        <courtesy-promotional
          :party="party"
          :ticket-groups="ticketGroups"
          :loading-ticket-groups="loadingTicketGroups"
          :edit-mode="editMode"
          :promotional-data="promotionalData"
          @close="closeDialog"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CourtesyPromotional from "./CourtesyPromotional.vue";

export default {
  components: { CourtesyPromotional },
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    loadingTicketGroups: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      promotionalData: null,
    };
  },
  methods: {
    openDialog(data = null) {
      this.editMode = !!data;
      this.promotionalData = data;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.promotionalData = null;
      this.editMode = false;
      this.$root.$emit("refresh-courtesy");
    },
  },
  mounted() {
    this.$root.$on("open-courtesy-promotional-modal", this.openDialog);
  },
};
</script>
