<template>
  <v-dialog v-model="dialog" width="700" scrollable eager>
    <v-card class="rounded-xl">
      <v-card-title class="d-flex justify-space-between align-center mb-2">
        <div>
          <v-icon left> mdi-creation </v-icon>
          <span class="text-h6">Avaliação do evento</span>
        </div>
        <v-chip v-if="analysis" :color="gradeColor" label>
          <span>{{ calculateGrade }}</span>
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="!analysis" type="info" dense text class="mb-0">
          Análise não encontrada para este evento. Se o evento foi criado
          recentemente, aguarde alguns minutos e tente novamente.
        </v-alert>

        <template v-else>
          <p class="text-body-2 mb-1">{{ analysis.shortResume }}</p>
          <div class="pt-2">
            <h6 class="mb-4">Sugestões</h6>
            <div class="d-flex flex-column gap-3">
              <template v-for="(suggestion, i) in sortedAnalysis">
                <suggestion-card
                  :key="suggestion.id"
                  :suggestion="suggestion"
                  :partyId="analysis.partyId"
                  @acknowledge="handleAcknowledge"
                />
                <v-divider
                  v-if="i < sortedAnalysis.length - 1"
                  class="my-2"
                ></v-divider>
              </template>
            </div>
          </div>
        </template>
      </v-card-text>
      <v-card-actions class="d-block">
        <small class="text-caption d-block text-center">
          As sugestões são geradas por IA em {{ analysis?.createdAt | date }} e
          podem conter erros ou inconsistências.
        </small>
        <v-btn block small text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SuggestionCard from "@/components/global/SuggestionCard.vue";
import PARTY from "@/services/admin/party";

export default {
  name: "AnalysisIADialog",
  components: { SuggestionCard },
  data: () => ({
    dialog: false,
    loading: false,
    partyId: null,
    analysis: null,
  }),

  methods: {
    open(partyId) {
      this.partyId = partyId;
      this.dialog = true;
      this.getAnalysis();
    },

    close() {
      this.dialog = false;
    },
    async getAnalysis() {
      this.loading = true;
      try {
        const response = await PARTY.analysis(
          this.selectedOrganization.id,
          this.partyId
        );
        this.analysis = response.analysis || null;
      } catch (error) {
        console.error(error);
        this.analysis = null;
      } finally {
        this.loading = false;
      }
    },
    async handleAcknowledge(suggestionId) {
      try {
        await PARTY.knowledgeSuggestion(
          this.selectedOrganization.id,
          this.partyId,
          suggestionId
        );

        this.analysis.analysis = this.analysis.analysis.map((s) => {
          if (s.id === suggestionId) {
            return { ...s, acknowledge: !s.acknowledge };
          }
          return s;
        });
      } catch (error) {
        console.error("Erro ao atualizar sugestão:", error);
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    gradeColor() {
      if (!this.analysis?.grade) return "primary";
      const grade = this.calculateGrade;
      if (grade <= 40) return "error";
      if (grade <= 60) return "warning";
      return "success";
    },
    calculateGrade() {
      if (!this.analysis?.analysis) return 100;
      const totalPointsLost = this.analysis.analysis
        .filter((s) => !s.acknowledge)
        .reduce((sum, s) => sum + s.pointsLost, 0);
      return Math.min(100, 100 - totalPointsLost);
    },
    sortedAnalysis() {
      if (!this.analysis?.analysis) return [];
      return [...this.analysis.analysis].sort((a, b) => {
        if (a.acknowledge === b.acknowledge) return 0;
        return a.acknowledge ? 1 : -1;
      });
    },
  },
  watch: {},
  mounted() {},
  props: {},
};
</script>

<style></style>
