<template>
  <v-dialog v-model="dialog" max-width="700" light>
    <v-card light class="grey lighten-3 pa-5 d-flex flex-column align-center">
      <div style="max-width: 400px; overflow-y: auto">
        <div class="d-flex align-center mb-4">
          <div class="align-center">
            <v-img src="/img/logo/logo-dark.png" width="80" />
          </div>
          <h5 class="mb-0 ml-4 black--text">
            Cancelamento do Evento {{ party.name }}
          </h5>
        </div>
        <v-card elevation="0" class="rounded-xl">
          <v-img :src="party.cover" :aspect-ratio="16 / 9" />
          <v-card-text>
            <p class="mb-2">Olá {{ firstName }},</p>
            <p class="mb-2">
              Temos uma notícia que gostaríamos de evitar: infelizmente, o
              evento
              <b class="font-weight-bold">{{ party.name }}</b> marcado para o
              dia
              <b class="font-weight-bold">{{
                party.date | date("DD [de] MMMM [de] YYYY [às] HH:mm")
              }}</b>
              foi cancelado.
            </p>
            <p class="mb-2" v-if="reason">
              {{ reason }}
            </p>
            <p class="mb-2" v-else>
              O motivo da cancelamento não foi informado.
            </p>
            <p class="mb-2">
              Agradecemos sua paciência e compreensão. Estamos trabalhando para
              trazer mais eventos incríveis em breve e esperamos contar com você
              novamente.
            </p>
            <p class="mb-2">
              Em caso de dúvidas, por favor entre em contato com nosso suporte
              pelo email <a href="#">{{ email }}</a
              >.
            </p>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { email } from "@/themeConfig.js";

export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
    reason: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      email,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    firstName() {
      return this.user.name.split(" ")[0];
    },
  },
  mounted() {
    this.$parent.$on("cancel-party-mail-preview", this.open);
  },
};
</script>
