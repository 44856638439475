<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" class="pb-0 pt-1">
          <v-text-field
            v-model="ticketBlock.name"
            :rules="[(v) => !!v || 'Nome é obrigatório']"
            label="Nome"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pb-0 pt-2">
          <v-text-field
            v-model="ticketBlock.price"
            :rules="[
              (v) => !!v || 'Preço é obrigatório',
              (v) => v > 0 || 'Preço deve ser maior que 0',
            ]"
            dense
            prefix="R$"
            label="Preço"
            type="number"
            step="0.01"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pb-0 pt-2">
          <v-text-field
            v-model="ticketBlock.quantity"
            dense
            :rules="[
              (v) => !!v || 'Quantidade é obrigatório',
              (v) =>
                v >= minQuantity ||
                'Quantidade deve ser maior ou igual a ' + minQuantity,
            ]"
            label="Quantidade"
            type="number"
            :min="minQuantity"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="">
          <b>Inicio das vendas</b>
          <v-btn-toggle
            class="d-flex"
            v-model="ticketBlock.startType"
            color="primary"
            mandatory
          >
            <v-btn :value="true" small class="flex-grow-1"> Data e hora </v-btn>
            <v-btn :value="false" small class="flex-grow-1">
              Após o anterior
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col v-if="ticketBlock.startType" cols="12" md="6" class="pb-0 pt-2">
          <v-text-field
            :max="new Date(party.endDate).toISOString().substr(0, 16)"
            v-model="ticketBlock.startDate"
            label="Inicio das vendas"
            type="datetime-local"
            :rules="dateRules.start"
            outlined
            dense
            ref="startDate"
          />
        </v-col>
        <v-col v-else cols="12" md="6" class="pb- pt-2">
          <div v-if="lastTicketBlock">
            <v-card outlined class="pa-3 px-3 d-flex align-center" rounded="lg">
              <div class="flex-grow-1">
                <p class="mb-0 lh-1 mb-1">Iniciar após</p>
                <p class="mb-0 font-weight-bold lh-1">
                  <b>{{ lastTicketBlock.name }}</b>
                </p>
                <span class="grey--text lh-1">
                  {{ lastTicketBlock.tickets.remaining }} ingressos restantes
                </span>
              </div>
              <h6 class="mb-0">
                {{ lastTicketBlock.price | currency(true) }}
              </h6>
            </v-card>
          </div>
          <v-alert v-else text color="warning" dense border="left" class="mb-0">
            <v-btn x-small icon @click="helpNextTicket" class="float-right">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
            <span class="grey--text text-14">
              Não há nenhum lote anterior com ingressos disponíveis para iniciar
              as vendas. <br />
              O lote permanecerá aguardando.
            </span>
          </v-alert>
        </v-col>
        <v-col cols="12" md="6" class="pb-0 pt-2">
          <v-text-field
            :label="`${ticketBlock.startType ? 'Fim' : 'Limite'} das vendas`"
            :max="new Date(party.endDate).toISOString().substr(0, 16)"
            v-model="ticketBlock.endDate"
            dense
            type="datetime-local"
            :rules="dateRules.end"
            outlined
            ref="endDate"
          />
        </v-col>
        <v-col cols="4" class="pb-0 pt-0">
          <v-switch
            v-model="ticketBlock.onlineSale"
            label="Venda online"
            :messages="[
              ticketBlock.onlineSale
                ? 'Venda online permitida'
                : 'Venda online não permitida',
            ]"
          ></v-switch>
        </v-col>
        <v-col class="pb-0 pt-0">
          <v-switch
            v-model="ticketBlock.allowTransfer"
            label="Transferência de titularidade"
            :messages="[
              ticketBlock.allowTransfer
                ? 'Transferência permitida'
                : 'Transferência não permitida',
            ]"
          ></v-switch>
        </v-col>
        <v-col
          v-show="!showAdvancedOptions && config.opMode"
          cols="12"
          class="pb-0"
        >
          <div class="d-flex justify-center">
            <v-btn @click="showAdvancedOptions = true" text small>
              <v-icon small left>mdi-chevron-down</v-icon>
              Opções avançadas
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="showAdvancedOptions" cols="12" class="">
          <v-card outlined class="pa-3" rounded="lg">
            <div class="d-flex justify-space-between align-center">
              <h6>Configurações avançadas</h6>
              <v-btn @click="showAdvancedOptions = false" icon small>
                <v-icon small>mdi-chevron-up</v-icon>
              </v-btn>
            </div>

            <!-- PrePurchase Alert -->
            <div class="d-flex align-center justify-space-between">
              <v-switch
                class="mt-0"
                :input-value="!!ticketBlock.prePurchaseAlert"
                @change="changePrePurchaseAlert"
                label="Alerta de pré-compra"
                :messages="[
                  ticketBlock.prePurchaseAlert
                    ? 'Alerta de pré-compra ativado'
                    : 'Alerta de pré-compra desativado',
                ]"
              />
              <v-btn
                v-if="!!ticketBlock.prePurchaseAlert"
                @click="openPrePurchaseAlertModal"
                text
                small
                :disabled="
                  !ticketBlock.prePurchaseAlert.title ||
                  !ticketBlock.prePurchaseAlert.message
                "
              >
                <v-icon small left>mdi-eye</v-icon>
                Previsualizar
              </v-btn>
            </div>

            <div v-if="!!ticketBlock.prePurchaseAlert" class="mt-4">
              <v-text-field
                v-model="ticketBlock.prePurchaseAlert.title"
                label="Título"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Título é obrigatório',
                  (v) =>
                    v.length <= 50 || 'Título deve ter no máximo 50 caracteres',
                ]"
              />
              <v-textarea
                v-model="ticketBlock.prePurchaseAlert.message"
                label="Mensagem"
                outlined
                dense
                hide-details="auto"
                :rules="[
                  (v) => !!v || 'Mensagem é obrigatória',
                  (v) =>
                    v.length <= 200 ||
                    'Mensagem deve ter no máximo 200 caracteres',
                ]"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <pre-purchase-alert-modal ref="prePurchaseAlertModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrePurchaseAlertModal from "../../../shop/PrePurchaseAlertModal.vue";

const defaultTicketBlock = () => ({
  id: null,
  name: "",
  price: 0,
  quantity: 0,
  startDate: new Date().toISOString().substr(0, 16),
  endDate: new Date().toISOString().substr(0, 16),
  active: true,
  onlineSale: false,
  allowTransfer: false,
  ticketGroup: null,
  startType: 0,
  prePurchaseAlert: false,
});
export default {
  components: {
    PrePurchaseAlertModal,
  },
  emits: ["input"],
  data: () => ({
    valid: false,
    showAdvancedOptions: false,
    disableRules: false,
  }),
  methods: {
    helpNextTicket() {
      this.$root.$emit("spotlight:run", {
        type: "paper",
        data: { paper: "admin/nextTicketBlock" },
      });
    },
    changePrePurchaseAlert(event) {
      const prePurchaseAlert = event ? { title: "", message: "" } : null;
      this.$set(this.ticketBlock, "prePurchaseAlert", prePurchaseAlert);
    },
    openPrePurchaseAlertModal() {
      this.$refs.prePurchaseAlertModal.open(
        Object.assign({}, this.ticketBlock)
      );
    },
  },
  watch: {
    valid(val) {
      this.$emit("valid", val);
    },
    "ticketBlock.startDate"(val) {
      this.$refs.endDate.validate();
    },
    "ticketBlock.endDate"(val) {
      this.$refs.startDate.validate();
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    ticketBlock: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },

    minQuantity() {
      if (!this.ticketBlock.tickets) return 1;
      return this.ticketBlock.tickets.sold + this.ticketBlock.tickets.pending;
    },
    dateRules() {
      const rules = {
        start: [
          (v) => !!v || "Data de início é obrigatória",
          (v) => new Date(v).getFullYear() < 3000 || "Data de início inválida",
          (v) =>
            v < this.ticketBlock.endDate ||
            "Data de início deve ser menor que a data de fim",
        ],
        end: [
          (v) => !!v || "Data de fim é obrigatória",
          (v) => new Date(v).getFullYear() < 3000 || "Data de fim inválida",
          (v) =>
            !this.ticketBlock.startType ||
            v > this.ticketBlock.startDate ||
            "Data de fim deve ser maior que a data de início",
        ],
      };

      if (this.disableRules) rules[this.disableRules] = [];

      return rules;
    },
  },
  props: {
    value: {
      type: Object,
      default: defaultTicketBlock,
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    lastTicketBlock: {
      type: Object | null,
      default: () => null,
    },
  },
};
</script>

<style></style>
