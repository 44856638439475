import applyedFilters from "../../utils/applyedFilters";
import header from "../../utils/header.js";

const PDFManager = require("@/utils/PDFManager").default;
const PAYMENT = require("@/utils/payment").default;
export default async function ({
  party,
  filter,
  reportData,
  ticketGroups,
  posSellers,
}) {
  const document = new PDFManager({
    orientation: "landscape",
    format: "a4",
    margin: 5,
    initialY: 10,
  });

  await header(document, party, `Relatório de Vendas por PDV`);

  applyedFilters(document, filter);
  const reducedData = reportData.reduce((acc, item) => {
    if (!item.PosSession) return acc;
    const pdvId = item.PosSession.id;
    if (!acc[pdvId])
      acc[pdvId] = {
        ...item.PosSession,
        PaymentTypes: {},
        TicketBlocks: {},
        total: 0,
        tickets: 0,
        courtesy: 0,
      };

    const total = item.Ticket.reduce(
      (acc, ticket) => acc + ticket.amount - ticket.platformFee,
      0
    );
    acc[pdvId].total += total;
    acc[pdvId].tickets += item.Ticket.length;

    const paymentType = item.paymentType;
    if (!acc[pdvId].PaymentTypes[paymentType])
      acc[pdvId].PaymentTypes[paymentType] = {
        tickets: 0,
        total: 0,
      };
    acc[pdvId].PaymentTypes[paymentType].tickets += item.Ticket.length;
    acc[pdvId].PaymentTypes[paymentType].total += total;

    for (const ticket of item.Ticket) {
      const TicketBlock = ticket.TicketBlock;

      if (!acc[pdvId].TicketBlocks[TicketBlock.id])
        acc[pdvId].TicketBlocks[TicketBlock.id] = {
          ...TicketBlock,
          tickets: 0,
          courtesy: 0,
          total: 0,
        };

      acc[pdvId].TicketBlocks[TicketBlock.id].tickets += 1;
      acc[pdvId].TicketBlocks[TicketBlock.id].total +=
        ticket.amount - ticket.platformFee;

      if (paymentType === "COURTESY") {
        acc[pdvId].courtesy += 1;
        acc[pdvId].TicketBlocks[TicketBlock.id].courtesy += 1;
      }
    }
    return acc;
  }, {});

  const pdvs = Object.values(reducedData);
  for (const pdv of pdvs) {
    // add title for pdv
    document.addTitle(`${pdv.name}`, 13, { spacing: 1 });
    document.addTitle(`${pdv.Device.serialNumber}`, 8, { spacing: 2 });

    // add table for payment types
    // document.addText("Por forma de pagamento", 11, { spacing: 1 });
    const paymentTypesHeaders = [
      "Tipo de Pagamento",
      { content: "Ingressos", styles: { halign: "center" } },
      { content: "Valor", styles: { halign: "right" } },
    ];
    const paymentTypesData = Object.entries(pdv.PaymentTypes).map(
      ([paymentType, { tickets, total }]) => [
        PAYMENT.paymentType[paymentType]?.text || paymentType,
        { content: formatInt(tickets), styles: { halign: "center" } },
        { content: formatCurrency(total), styles: { halign: "right" } },
      ]
    );
    document.addTable(paymentTypesHeaders, paymentTypesData, { spacing: 1 });

    // Add table for ticket blocks
    // document.addText("Por setor", 12, { spacing: 1 });
    const ticketBlocksHeaders = [
      "Setor",
      "Lote",
      { content: "Vendidos", styles: { halign: "center" } },
      { content: "Cortesias", styles: { halign: "center" } },
      { content: "Total", styles: { halign: "center" } },
      { content: "Valor", styles: { halign: "right" } },
    ];
    const ticketBlocksData = Object.values(pdv.TicketBlocks).map(
      ({ name, tickets, courtesy, total, TicketGroup }) => [
        TicketGroup.name,
        name,
        {
          content: formatInt(tickets - courtesy),
          styles: { halign: "center" },
        },
        { content: formatInt(courtesy), styles: { halign: "center" } },
        { content: formatInt(tickets), styles: { halign: "center" } },
        { content: formatCurrency(total), styles: { halign: "right" } },
      ]
    );

    // add footer
    ticketBlocksData.push([
      "",
      {
        content: "Total",
        styles: { halign: "right", fontStyle: "bold", fontSize: 11 },
      },
      {
        content: formatInt(pdv.tickets - pdv.courtesy),
        styles: { halign: "center", fontStyle: "bold", fontSize: 11 },
      },
      {
        content: formatInt(pdv.courtesy),
        styles: { halign: "center", fontStyle: "bold", fontSize: 11 },
      },
      {
        content: formatInt(pdv.tickets),
        styles: { halign: "center", fontStyle: "bold", fontSize: 11 },
      },
      {
        content: formatCurrency(pdv.total),
        styles: { halign: "right", fontStyle: "bold", fontSize: 11 },
      },
    ]);

    document.addTable(ticketBlocksHeaders, ticketBlocksData, { spacing: 1 });

    document.addDivider();
  }

  return document;
}

const formatInt = (value) => {
  return new Intl.NumberFormat("pt-BR").format(value);
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};
