import moment from "moment";

export default function (doc, filters) {
  const filtersList = [];

  // Courtesy
  if (!filters.courtesy) filtersList.push("Não inclui cortesias");

  // Dates
  if (filters.dates) {
    const [startDate, endDate] = filters.dates;
    if (moment(startDate).isSame(endDate, "day"))
      filtersList.push(
        `Vendas do dia ${moment(startDate).format("DD/MM/YYYY")}`
      );
    else
      filtersList.push(
        `Vendas entre ${moment(startDate).format("DD/MM/YYYY")} e ${moment(
          endDate
        ).format("DD/MM/YYYY")}`
      );
  }

  // sellers
  if (filters.sellers && filters.sellers.length > 0) {
    filtersList.push(
      `${filters.sellers.length} vendedor${
        filters.sellers.length > 1 ? "es" : ""
      }`
    );
  }

  // ticketBlocks
  if (filters.ticketBlocks && filters.ticketBlocks.length > 0) {
    filtersList.push(
      `${filters.ticketBlocks.length} lotes${
        filters.ticketBlocks.length > 1 ? "es" : ""
      }`
    );
  }

  // Add filters to document
  if (filtersList.length === 0) {
    doc.addText(`Nenhum filtro aplicado`, 11, { spacing: 6 });
  } else {
    const filterText = filtersList.join(", ");
    doc.addText(`Filtros: ${filterText}`, 11, { spacing: 6 });
  }
  return;
}
