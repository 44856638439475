<template>
  <v-card outlined class="pa-3" rounded="lg">
    <div class="d-flex align-center justify-space-between">
      <h6 class="mb-0">
        <v-icon left>mdi-link</v-icon>
        Links promocionais
      </h6>
    </div>

    <v-alert v-if="error" type="error" class="mb-0">{{ error }}</v-alert>

    <!-- <v-text-field
      v-model="searchCourtesyEmitters"
      label="Pesquisar"
      class="mt-3"
      outlined
      dense
      hide-details
    ></v-text-field> -->
    <v-data-table
      :headers="headers"
      :items="courtesyPromotionals"
      :loading="loading"
      item-key="id"
      sort-by="Payment.createdAt"
      sort-desc
      single-expand
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:item.emitted="{ item }">
        <div class="d-flex lh-1">
          <v-progress-circular
            class="mr-1"
            :rotate="-90"
            :size="16"
            :width="4"
            :value="(item.Tickets.length / item.maxTickets) * 100"
            color="primary"
          />
          {{ item.Tickets.length | number }}/{{ item.maxTickets | number }}
        </div>
      </template>
      <template v-slot:item.TicketBlock.name="{ item }">
        {{ item.TicketBlock.TicketGroup.name }}
        <small class="d-block">
          {{ item.TicketBlock.name }}
        </small>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip color="error" label small v-if="!item.active">Inativo</v-chip>
        <v-chip
          color="error"
          label
          small
          v-else-if="item.Tickets.length >= item.maxTickets"
        >
          Esgotado
        </v-chip>
        <v-chip
          v-else-if="item.maxDate && item.maxDate < new Date()"
          color="error"
          label
          small
        >
          Expirado
        </v-chip>
        <v-chip color="success" label small v-else> Ativo </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon small @click="editCourtesyPromotion(item)" class="mr-2">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="copyCortesyPromotionLink(item)"
          :disabled="copied == item.id"
        >
          <v-icon small>
            mdi-{{ copied == item.id ? "check" : "content-copy" }}
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-3">
          <v-card outlined rounded="lg" class="mb-3">
            <v-card-text class="d-flex flex-wrap justify-space-around px-">
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ item.maxPerUser | number }}
                </h6>
                <span class="text-caption">Maximo por usuário</span>
              </div>
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ item.maxTickets | number }}
                </h6>
                <span class="text-caption">Maximo de resgates </span>
              </div>
            </v-card-text>
          </v-card>
          <v-card outlined class="pa-3" rounded="lg">
            <b>Contemplados</b>
            <p class="mb-0" v-if="item.Tickets.length == 0">
              Nenhuma cortesia emitida
            </p>
            <v-data-table
              v-else
              :items="item.Tickets"
              :headers="[
                { text: 'Contemplado', value: 'Owner.name' },
                { text: 'Status', value: 'Payment.status' },
                { text: 'Data', value: 'createdAt' },
              ]"
              :hide-default-footer="item.Tickets.length < 5"
              dense
              class="mt-3"
            >
              <template v-slot:item.Owner.name="{ item }">
                <div class="d-flex gap-2 align-center">
                  <base-avatar
                    v-if="item.Owner.name"
                    :src="item.Owner.photo"
                    :seed="item.Owner.id"
                    :size="24"
                  />
                  <v-icon v-else>mdi-account-question</v-icon>
                  <div>
                    <b>
                      {{ item.Owner.name || "Conta não criada" }}
                    </b>
                    <small class="d-block">
                      {{ item.Owner.phone }}
                    </small>
                  </div>
                </div>
              </template>
              <template v-slot:item.Payment.status="{ item }">
                <v-chip
                  :color="
                    PAYMENT.ticketStatus[item.Payment?.status]?.color || 'error'
                  "
                  small
                  label
                >
                  {{
                    PAYMENT.ticketStatus[item.Payment?.status]?.text ||
                    "Não pago"
                  }}
                </v-chip>
              </template>
              <template v-slot:item.TicketBlock.name="{ item }">
                {{ item.TicketBlock.TicketGroup.name }}
                <small class="d-block">
                  {{ item.TicketBlock.name }}
                </small>
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
              </template>
            </v-data-table>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <courtesy-promotional-modal :party="party" :ticket-groups="ticketGroupsData" :loading-ticket-groups="loadingTicketsStatus" />
  </v-card>
</template>

<script>
import COURTESY_PROMOTIONAL from "@/services/admin/courtesyPromotional";
import TICKET from "@/services/admin/ticket";
import PAYMENT from "@/utils/payment";

import QrcodeVue from "qrcode.vue";
import CourtesyPromotionalModal from './CourtesyPromotionalModal.vue'

export default {
  components: {
    QrcodeVue,
    CourtesyPromotionalModal
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PAYMENT,
      loading: false,
      localTicketGroups: [],
      isLoadingTickets: false,

      courtesyPromotionals: [],
      searchCourtesyPromotionals: "",
      error: null,
      expanded: [],

      copied: null,

      headers: [
        { text: "Name", value: "name" },
        { text: "Emitidas", value: "emitted" },
        { text: "Lote", value: "TicketBlock.name" },
        { text: "Status", value: "status" },
        { text: "", value: "actions", align: "end" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    async getCourtesyPromotionals() {
      this.loading = true;
      try {
        const { courtesyPromotionals } = await COURTESY_PROMOTIONAL.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.courtesyPromotionals = courtesyPromotionals;
      } catch (error) {
        this.error = error.message || "Erro ao buscar cortesias.";
      } finally {
        this.loading = false;
      }
    },
    async getTickets() {
      try {
        this.isLoadingTickets = true;
        this.localTicketGroups = [];

        const response = await TICKET.canSell(
          this.party.organizationId,
          this.party.id
        );
        this.localTicketGroups = response.ticketGroups;
      } catch (error) {
        console.error(error);
        this.error = error.message;
      } finally {
        this.isLoadingTickets = false;
      }
    },
    copyCortesyPromotionLink(promotion) {
      var url = `${window.location.origin}/redeem/courtesy/${this.party.slug}/${promotion.id}`;
      this.copied = promotion.id;
      setTimeout(() => {
        this.copied = null;
      }, 3000);
      this.copyLink(url);
    },
    copyLink(link) {
      navigator.clipboard.writeText(link).then(() => {
        this.linkCopied = true;
        setTimeout(() => {
          this.linkCopied = false;
        }, 2000);
      });
    },
    editCourtesyPromotion(item) {
      this.$root.$emit('open-courtesy-promotional-modal', item);
    },
  },
  computed: {
    ticketGroupsData() {
      return this.localTicketGroups;
    },
    loadingTicketsStatus() {
      return this.isLoadingTickets;
    }
  },
  mounted() {
    this.getCourtesyPromotionals();
    this.getTickets();
    this.$root.$on("refresh-courtesy", this.getCourtesyPromotionals);
  },
};
</script>

<style></style>
