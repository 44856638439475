<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    overlay-opacity="0.9"
    scrollable
    :persistent="exporting"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between align-start">
        Cortesias por Setor
        <v-btn
          x-small
          @click="generateExcel"
          color="primary"
          :disabled="!ticketGroups.length || exporting"
          :loading="exporting"
        >
          <v-icon left x-small>mdi-file-excel</v-icon>
          Exportar
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-3">
        <!-- <v-card outlined>
          <apexCharts
            type="bar"
            height="300"
            :options="options"
            :series="series"
          />
        </v-card> -->

        <v-alert
          v-if="pendingCortesyEmitter > 0"
          @click="$router.push('#courtesy') && (dialog = false)"
          style="cursor: pointer"
          type="warning"
          dense
          text
        >
          <b>
            {{ pendingCortesyEmitter | number }} cortesia{{
              pendingCortesyEmitter == 1 ? "" : "s"
            }}
          </b>
          ainda não emitidos por responsáveis
          <small class="d-block">Clique para ver detalhes</small>
        </v-alert>

        <v-card outlined>
          <v-card-text class="d-flex flex-wrap justify-space-around">
            <div class="text-center px-4">
              <h6 class="mt-2 mb-0">
                {{ summary.count | number }}
              </h6>
              <span class="text-caption">Cortesias Emitidos</span>
            </div>
            <div class="text-center px-4">
              <h6 class="mt-2 mb-0">
                {{ summary.validated | number }}
              </h6>
              <span class="text-caption">Validados</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          v-for="group in ticketGroups"
          :key="group.id"
          outlined
          class="pa-3"
        >
          <b>{{ group.name }}</b>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left w-full">Lote</th>
                  <th class="text-center">Emitidas</th>
                  <th class="text-right">Válidados</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="block in group.TicketBlock"
                  :key="block.id"
                  style="border-top: 1px solid red !important"
                >
                  <td class="font-weight-medium w-full">
                    {{ block.name }}
                  </td>
                  <td class="text-center">{{ block.count | number }}</td>
                  <td class="text-center">{{ block.validated | number }}</td>
                </tr>
                <tr
                  class="font-weight-bold"
                  style="border-top: 2px solid #000 !important"
                >
                  <td class="font-weight-bold text-end text-15 w-full">
                    Total
                  </td>
                  <td class="text-center text-15">{{ group.total.count | number }}</td>
                  <td class="text-center text-15">
                    {{ group.total.validated | number }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="exporting" block text @click="dialog = false">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApexCharts from "vue-apexcharts";
import ExcelExport from "@/utils/exportExcel/courtesy";

export default {
  name: "CourtesyByTicketBlockModal",
  components: { ApexCharts },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pendingCortesyEmitter: {
      type: [Number, Boolean],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    exporting: false,
  }),
  computed: {
    validTickets() {
      return this.data.raw.tickets.filter(
        (t) =>
          t.Payment.status === "succeeded" &&
          t.Payment.paymentType === "COURTESY"
      );
    },
    ticketGroups() {
      return Object.values(
        this.validTickets.reduce((acc, t) => {
          const block = t.TicketBlock;
          const group = block.TicketGroup;

          if (!acc[group.id])
            acc[group.id] = {
              name: group.name,
              id: group.id,
              TicketBlock: {},
              total: {
                count: 0,
                validated: 0,
              },
            };

          acc[group.id].total.count++;
          if (t._count.TicketEntry) acc[group.id].total.validated++;

          if (!acc[group.id].TicketBlock[block.id])
            acc[group.id].TicketBlock[block.id] = {
              name: block.name,
              count: 0,
              validated: 0,
            };

          acc[group.id].TicketBlock[block.id].count++;
          if (t._count.TicketEntry)
            acc[group.id].TicketBlock[block.id].validated++;

          return acc;
        }, {})
      )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group) => {
          group.TicketBlock = Object.values(group.TicketBlock).sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          return group;
        });
    },
    summary() {
      return this.ticketGroups.reduce(
        (acc, g) => {
          acc.count += g.total.count;
          acc.validated += g.total.validated;
          return acc;
        },
        { count: 0, validated: 0 }
      );
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async generateExcel() {
      try {
        this.exporting = true;
        await ExcelExport({
          party: this.data.party,
          ticketGroups: this.ticketGroups,
          tickets: this.validTickets,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.exporting = false;
      }
    },
  },
};
</script>

<style></style>
