<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :persistent="loading"
    scrollable
    @click:outside="close"
    eager
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="d-flex flex-column align-start">
        <span class="text-16 lh-1"> Editar bilheteria </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent>
          <v-text-field
            v-model="ticketOffice.name"
            label="Nome"
            :rules="[(v) => !!v.length || 'Nome é obrigatório']"
            class="mt-2"
            outlined
          />
          <div class="d-flex gap-4 flex-wrap">
            <v-checkbox
              v-model="ticketOffice.allowPaymentMethods"
              label="Dinheiro"
              value="money"
              class="mt-0"
              hide-details
              :rules="[
                (v) => !!v.length || 'Método de pagamento é obrigatório',
              ]"
            />
            <v-checkbox
              v-model="ticketOffice.allowPaymentMethods"
              label="PIX"
              value="pix"
              class="mt-0"
              hide-details
              :rules="[
                (v) => !!v.length || 'Método de pagamento é obrigatório',
              ]"
            />
            <v-checkbox
              v-model="ticketOffice.allowPaymentMethods"
              label="Cartão de Crédito"
              value="credit"
              class="mt-0"
              hide-details
              :rules="[
                (v) => !!v.length || 'Método de pagamento é obrigatório',
              ]"
            />
            <v-checkbox
              v-model="ticketOffice.allowPaymentMethods"
              label="Cartão de Débito"
              value="debit"
              class="mt-0"
              hide-details
              :rules="[
                (v) => !!v.length || 'Método de pagamento é obrigatório',
              ]"
            />
            <v-checkbox
              v-model="ticketOffice.allowPaymentMethods"
              label="Cortesia"
              value="courtesy"
              class="mt-0"
              hide-details
              :rules="[
                (v) => !!v.length || 'Método de pagamento é obrigatório',
              ]"
            />
          </div>
        </v-form>
        <v-alert v-if="error" type="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn  text :disabled="loading" @click="close">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ticket-office-adopt-modal
      @success="success"
      :party="party"
      ref="adoptModal"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import Scanner from "../../../global/scanner/Scanner.vue";
import TicketOfficeAdoptModal from "./TicketOfficeAdoptModal.vue";

const defaultTicketOffice = () => ({
  name: "",
  allowPaymentMethods: ["money", "pix", "credit", "debit"],
  ticketBlocks: [],
});

export default {
  components: { Scanner, TicketOfficeAdoptModal },
  data: () => ({
    dialog: false,
    error: false,
    valid: false,
    loading: false,
    ticketOffice: defaultTicketOffice(),
  }),

  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;

        await POS.editPos(
          this.party.organizationId,
          this.ticketOffice.id,
          {
            name: this.ticketOffice.name,
            allowPaymentMethods: this.ticketOffice.allowPaymentMethods,
          }
        );

        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    success() {
      this.loading = false;
      this.error = false;
      this.count++;
    },
    open(session) {
      this.loading = false;
      this.error = false;
      this.ticketOffice = {
        id: session.id,
        name: session.name,
        allowPaymentMethods: session.allowPaymentMethods,
      };
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.count = 0;
      this.valid = false;
      this.ticketOffice = defaultTicketOffice();
      this.dialog = false;
      this.$emit("success");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("edit-ticket-office-modal", this.open);
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array | Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
