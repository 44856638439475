<template>
  <v-dialog v-model="dialog" max-width="800" overlay-opacity="0.9" scrollable>
    <v-card :loading="loading">
      <v-card-title class="d-flex justify-space-between align-start">
        {{ title }}
        <!-- <v-btn
          x-small
          @click="generateExcel"
          color="primary"
          :disabled="!pos.length || exporting"
          :loading="exporting"
        >
          <v-icon left x-small>mdi-file-excel</v-icon>
          Exportar
        </v-btn> -->
      </v-card-title>
      <v-card-text v-if="dialog">
        <v-card outlined>
          <v-card-text class="d-flex flex-wrap justify-space-around">
            <div class="text-center px-4">
              <h6 class="mt-2 mb-0">
                {{ resume.tickets.qnt }}
              </h6>
              <span class="text-caption">Ingressos</span>
            </div>
            <div class="text-center px-4">
              <h6 class="mt-2 mb-0">
                {{ resume.tickets.totalValue | currency }}
              </h6>
              <span class="text-caption">Vendas</span>
            </div>
            <div class="text-center px-4">
              <h6 class="mt-2 mb-0">
                {{
                  (resume.tickets.totalValue / resume.tickets.qnt) | currency
                }}
              </h6>
              <span class="text-caption"> Ticket Médio </span>
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined class="pa-3 pb-0 mt-2">
          <div class="d-flex justify-space-between">
            <b>Total</b>
            <v-btn-toggle mandatory v-model="display" class="ml-2">
              <v-btn small> R$ </v-btn>
              <v-btn small> Qtde </v-btn>
            </v-btn-toggle>
          </div>
          <v-row class="mx-0" dense>
            <v-col cols="12" md="6">
              <apexCharts
                type="donut"
                height="350"
                :options="paymentOptions"
                :series="paymentSeries"
              />
            </v-col>
            <v-col cols="12" md="6">
              <apexCharts
                type="donut"
                height="350"
                :options="ticketBlockOptions"
                :series="ticketBlockSeries"
              />
            </v-col>
          </v-row>
        </v-card>

        <v-divider class="my-4" />

        <div class="d-flex flex-column gap-3">
          <h5 class="mb-0">Vendas por PDV</h5>
          <v-card v-for="p in pos" :key="p.id" class="pa-3" outlined>
            <b>
              <v-avatar v-if="!p.active" color="warning" size="16">
                <v-icon x-small color="white">mdi-connection</v-icon>
              </v-avatar>
              {{ p.name || p.Address?.name }}
            </b>
            <div class="d-flex flex-column gap-2">
              <v-card outlined>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Forma de Pagamento</th>
                        <th class="text-center">Qtde</th>
                        <th
                          class="text-right"
                          style="max-width: 100px !important"
                        >
                          Valor
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="pt in p.paymentTypes" :key="pt.paymentType">
                        <td>{{ payment.paymentType[pt.paymentType].text }}</td>
                        <td class="text-center">{{ pt.qnt }}</td>
                        <td class="text-right">
                          {{ pt.totalValue | currency }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <v-card outlined>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Lote</th>
                        <th class="text-center">Qtde</th>
                        <th class="text-right">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="tb in p.ticketBlocks" :key="tb.id">
                        <td>
                          {{ tb.TicketGroup?.name }}
                          <small class="d-block">{{ tb.name }}</small>
                        </td>

                        <td class="text-center">{{ tb.qnt }}</td>
                        <td class="text-right">
                          {{ tb.totalValue | currency }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <div class="d-flex justify-end gap-2">
                <b>Total:</b>
                <span class="text-14">
                  {{ p.qnt }} ingresso{{ p.qnt == 1 ? "" : "s" }}
                  •
                  {{ p.totalValue | currency }}
                </span>
              </div>
            </div>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import payment from "../../../../../utils/payment";
import ApexCharts from "vue-apexcharts";
import ExcelExport from "@/utils/exportExcel/salesByPos";

export default {
  components: { ApexCharts },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filteredTickets: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      display: 0,
      exporting: false,
      payment,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async generateExcel() {
      try {
        this.exporting = true;
        await ExcelExport({
          party: this.data.party,
          pos: this.pos,
          tickets: this.filteredTickets,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.exporting = false;
      }
    },
  },

  computed: {
    resume() {
      const resume = {
        tickets: {
          qnt: 0,
          totalValue: 0,
          payments: this.filteredTickets.length,
        },
        paymentTypes: {},
        ticketBlocks: {},
      };

      this.pos.forEach((pos) => {
        resume.tickets.qnt += pos.qnt;
        resume.tickets.totalValue += pos.totalValue;
        pos.paymentTypes.forEach((paymentType) => {
          if (!resume.paymentTypes[paymentType.paymentType]) {
            resume.paymentTypes[paymentType.paymentType] = {
              qnt: 0,
              totalValue: 0,
            };
          }
          resume.paymentTypes[paymentType.paymentType].qnt += paymentType.qnt;
          resume.paymentTypes[paymentType.paymentType].totalValue +=
            paymentType.totalValue;
        });

        pos.ticketBlocks.forEach((ticketBlock) => {
          if (!resume.ticketBlocks[ticketBlock.id]) {
            resume.ticketBlocks[ticketBlock.id] = {
              ...ticketBlock,
              qnt: 0,
              totalValue: 0,
            };
          }
          resume.ticketBlocks[ticketBlock.id].qnt += ticketBlock.qnt;
          resume.ticketBlocks[ticketBlock.id].totalValue +=
            ticketBlock.totalValue;
        });
      });

      return {
        ...resume,
        ticketBlocks: Object.values(resume.ticketBlocks),
      };
    },
    pos() {
      const pos = this.filteredTickets.reduce((acc, ticket) => {
        const payment = ticket.Payment;
        const id = payment.PosSession.id;
        if (!acc[id]) {
          acc[id] = {
            ...payment.PosSession,
            qnt: 0,
            courtesy: 0,
            totalValue: 0,
            payments: 0,
            paymentTypes: {},
            ticketBlocks: {},
          };
        }

        acc[id].qnt += 1;
        acc[id].totalValue += ticket.amount - ticket.platformFee;
        acc[id].payments++;
        const paymentMethod = payment.paymentMethod;
        if (paymentMethod == "courtesy") acc[id].courtesy++;

        const paymentType = payment.paymentType;
        if (!acc[id].paymentTypes[paymentType]) {
          acc[id].paymentTypes[paymentType] = {
            qnt: 0,
            totalValue: 0,
          };
        }
        acc[id].paymentTypes[paymentType].qnt += 1;
        acc[id].paymentTypes[paymentType].totalValue +=
          ticket.amount - ticket.platformFee;

        const ticketBlock = ticket.TicketBlock;
        if (!acc[id].ticketBlocks[ticketBlock.id]) {
          acc[id].ticketBlocks[ticketBlock.id] = {
            ...ticketBlock,
            qnt: 0,
            courtesy: 0,
            totalValue: 0,
          };
        }
        acc[id].ticketBlocks[ticketBlock.id].qnt++;
        if (paymentMethod == "courtesy")
          acc[id].ticketBlocks[ticketBlock.id].courtesy++;
        acc[id].ticketBlocks[ticketBlock.id].totalValue +=
          ticket.amount - ticket.platformFee;

        return acc;
      }, {});

      return Object.values(pos).map((v) => ({
        ...v,
        paymentTypes: Object.entries(v.paymentTypes).map(
          ([paymentType, value]) => ({ ...value, paymentType })
        ),
        ticketBlocks: Object.values(v.ticketBlocks),
      }));
    },
    paymentOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: Object.keys(this.resume.paymentTypes).map(
          (key) => this.payment.paymentType[key].text
        ),
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              if (this.display) {
                return `${value} ingressos${value != 1 ? "s" : ""}`;
              }
              return value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
      };
    },
    paymentSeries() {
      return Object.values(this.resume.paymentTypes).map((v) =>
        this.display ? v.qnt : v.totalValue
      );
    },
    ticketBlockOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: this.resume.ticketBlocks.map(
          (tb) =>
            `${tb.TicketGroup?.name} <br> <span class="ml-4">${tb.name}</span>`
        ),
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              if (this.display) {
                return `${value} ingressos${value != 1 ? "s" : ""}`;
              }
              return value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
      };
    },
    ticketBlockSeries() {
      return this.resume.ticketBlocks.map((p) =>
        this.display ? p.qnt : p.totalValue
      );
    },
  },
};
</script>

<style></style>
