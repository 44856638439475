import http from "@/http-common";
import ImageConverter from "@/utils/ImageConverter";

const get = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/table`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const upsert = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/table`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const upload = async (orgId, partyId, file) => {
  if (!file) throw new Error("file is required");

  const imageConverter = new ImageConverter();
  const webpFile = await imageConverter.webp(file);

  var formData = new FormData();
  formData.append("file", webpFile);

  return http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/table/background`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

export default {
  get,
  upsert,
  upload,
};
