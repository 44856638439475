<template>
  <v-card outlined :loading="loading || innerLoading" v-intersect="onIntersect">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0 lh-1">Cortesias</h6>
      <div class="d-flex align-center gap-1">
        <b class="mb-0 lh-1 text-body-2 font-weight-bold">
          {{ courtesyTickets.length | number }} ingresso{{
            courtesyTickets.length == 1 ? "" : "s"
          }}
        </b>
        <v-btn
          icon
          @click="() => $refs.modal.open()"
          :disabled="loading || !courtesyTickets.length"
          small
        >
          <v-icon small>mdi-arrow-expand</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-alert
        v-if="pendingCortesyEmitter > 0"
        @click="$router.push('#courtesy')"
        style="cursor: pointer"
        type="warning"
        dense
        text
      >
        <b>
          {{ pendingCortesyEmitter | number }} cortesia{{
            pendingCortesyEmitter == 1 ? "" : "s"
          }}
        </b>
        ainda não emitidos por responsáveis
        <small class="d-block">Clique para ver detalhes</small>
      </v-alert>
      <v-data-table
        :items="sellersList"
        :headers="headers"
        hide-default-footer
        single-expand
        item-key="seller.id"
        show-expand
      >
        <template v-slot:item.seller.name="{ item }">
          {{ item.seller?.name || "Venda direta" }}
        </template>
        <template v-slot:item.count="{ item }">
          {{ item.tickets.length | number }} ingresso{{
            item.tickets.length == 1 ? "" : "s"
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Contemplado</th>
                    <th class="text-right">Lote</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ticket in item.tickets" :key="ticket.id">
                    <td>
                      <b class="d-block">
                        {{ ticket.Owner?.name || "Não cadastrado" }}
                      </b>
                      {{ ticket.Owner?.phone }}
                    </td>
                    <td class="text-right">
                      {{ ticket.TicketBlock.TicketGroup.name }} -
                      {{ ticket.TicketBlock.name }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <courtesy-by-ticket-block-modal
      :loading="loading"
      :data="data"
      ref="modal"
      :pending-cortesy-emitter="pendingCortesyEmitter"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import COURTESY_EMITTER from "@/services/admin/courtesyEmitter";
import CourtesyByTicketBlockModal from "./modals/CourtesyByTicketBlockModal.vue";

export default {
  components: { CourtesyByTicketBlockModal },
  data: () => ({
    expanded: null,
    isIntersecting: false,
    viewed: false,
    headers: [
      {
        text: "Vendedor",
        value: "seller.name",
        align: "left",
      },
      {
        text: "Ingressos",
        value: "count",
        align: "left",
      },
      { text: "", value: "data-table-expand" },
    ],
    courtesyEmitters: null,
    innerLoading: false,
  }),
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
        this.loadCourtesyTickets();
      } else {
        this.isIntersecting = false;
      }
    },
    async loadCourtesyTickets() {
      try {
        this.innerLoading = true;
        if (!!this.courtesyEmitters) return;
        const data = await COURTESY_EMITTER.getAll(
          this.data.party.Organization.id,
          this.data.party.id
        );
        this.courtesyEmitters = data.courtesyEmitters;
      } catch (e) {
        console.error(e);
      } finally {
        this.innerLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    pendingCortesyEmitter() {
      if (!this.courtesyEmitters) return false;
      return this.courtesyEmitters.reduce(
        (acc, emitter) =>
          acc + (emitter.quantity || 0) - emitter.Tickets.length,
        0
      );
    },
    courtesyTickets() {
      if (!this.viewed) return [];
      return this.data.tickets.filter(
        (ticket) =>
          ticket.Payment?.status == "succeeded" &&
          ticket.Payment?.paymentType == "COURTESY"
      );
    },
    sellersList() {
      return Object.values(
        this.courtesyTickets.reduce((acc, ticket) => {
          var id = ticket.Seller?.id || ticket.SaleLink?.id || "unknown";
          if (!id) return acc;

          if (!acc[id])
            acc[id] = {
              seller: ticket.Seller || ticket.SaleLink,
              tickets: [],
            };

          acc[id].tickets.push({
            ...ticket,
            Seller: undefined,
            SaleLink: undefined,
          });
          return acc;
        }, {})
      ).map((seller) => ({
        ...seller,
        count: seller.tickets.length,
      }));
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
