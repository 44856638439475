<template>
  <v-card outlined class="pa-3" rounded="lg">
    <div class="d-flex align-center justify-space-between">
      <h6 class="mb-0">
        <v-icon left>mdi-account-tie-hat</v-icon>
        Responsaveis
      </h6>
      <v-btn text @click="copyLinkModal = true" small>
        <v-icon small left>mdi-link-variant</v-icon>
        Copiar link
      </v-btn>
    </div>
    <v-progress-linear
      :height="loading ? null : 20"
      :value="totalPercentage"
      class="rounded mt-2"
      color="primary"
    >
      <b>{{ totalEmittedTickets | number }}/{{ totalCourtesyTickets | number }}</b>
    </v-progress-linear>
    <v-alert v-if="error" type="error" class="mb-0">{{ error }}</v-alert>

    <v-text-field
      v-model="searchCourtesyEmitters"
      label="Pesquisar"
      class="mt-3"
      outlined
      dense
      hide-details
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="courtesyEmittersByResponsible"
      :loading="loading"
      item-key="id"
      sort-by="Payment.createdAt"
      sort-desc
      single-expand
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:item.Responsible.name="{ item }">
        <div class="d-flex align-center gap-2">
          <base-avatar
            :src="item.Responsible.photo"
            :seed="item.Responsible.id"
            v-if="item.Responsible.name"
          />
          <v-icon v-else>mdi-account-question</v-icon>
          <div>
            <b class="d-block lh-1">{{
              item.Responsible.name || "Conta não criada"
            }}</b>
            <small class="lh-1">{{ item.Responsible.phone }}</small>
          </div>
        </div>
      </template>
      <template v-slot:item.emitted="{ item }">
        <div class="d-flex lh-1">
          <v-progress-circular
            class="mr-1"
            :rotate="-90"
            :size="16"
            :width="4"
            :value="(item.emitted / item.count) * 100"
            color="primary"
          />
          {{ item.emitted | number }}/{{ item.count | number }}
        </div>
      </template>
      <template v-slot:item.courtesyEmitters.length="{ item }">
        {{ item.courtesyEmitters.length }} lote{{
          item.courtesyEmitters.length > 1 ? "s" : ""
        }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-3">
          <v-card outlined class="pa-3 mb-3" rounded="lg">
            <div class="d-flex align-center gap-2 justify-space-between mb-2">
              <b> Lotes </b>
              <v-btn
                x-small
                text
                color="primary"
                @click="renotifyCourtesy(item.Responsible.id)"
                :disabled="
                  !!loadingResend ||
                  renotifyedCourtesy.includes(item.Responsible.id) ||
                  item.count <= item.emitted
                "
                :loading="loadingResend === item.Responsible.id"
              >
                {{
                  renotifyedCourtesy.includes(item.Responsible.id)
                    ? "Notificação enviada"
                    : "Reenviar notificação"
                }}
              </v-btn>
            </div>
            <div class="d-flex flex-column gap-1">
              <template v-for="(courtesyEmitter, i) in item.courtesyEmitters">
                <v-divider v-if="i" :key="`divider-${courtesyEmitter.id}`" />
                <div
                  :key="`div-${courtesyEmitter.id}`"
                  class="d-flex align-center gap-2"
                >
                  <p class="mb-0" style="flex: 2">
                    {{ courtesyEmitter.TicketBlock.TicketGroup.name }} -
                    {{ courtesyEmitter.TicketBlock.name }}
                  </p>
                  <v-progress-linear
                    height="16"
                    style="flex: 1"
                    :value="
                      (courtesyEmitter.Tickets.length /
                        courtesyEmitter.quantity) *
                      100
                    "
                    class="rounded"
                  >
                    {{ courtesyEmitter.Tickets.length }}/{{
                      courtesyEmitter.quantity
                    }}
                  </v-progress-linear>
                  <v-btn
                    icon
                    x-small
                    :disabled="
                      !!loadingCancel ||
                      courtesyEmitter.Tickets.length >= courtesyEmitter.quantity
                    "
                    :loading="loadingCancel === courtesyEmitter.id"
                    @click="cancelCourtesy(courtesyEmitter.id)"
                  >
                    <v-icon small>mdi-cancel</v-icon>
                  </v-btn>
                </div>
              </template>
            </div>
          </v-card>

          <v-card outlined class="pa-3" rounded="lg">
            <b>Contemplados</b>
            <p class="mb-0" v-if="item.Tickets.length == 0">
              Nenhuma cortesia emitida
            </p>

            <v-data-table
              v-else
              :items="item.Tickets"
              :headers="[
                { text: 'Contemplado', value: 'Owner.name' },
                { text: 'Lote', value: 'TicketBlock.name' },
                { text: 'Data', value: 'createdAt' },
              ]"
              hide-default-footer
              dense
              class="mt-3"
              :items-per-page="item.emitted"
            >
              <template v-slot:item.Owner.name="{ item }">
                <div class="d-flex gap-2 align-center">
                  <base-avatar
                    v-if="item.Owner.name"
                    :src="item.Owner.photo"
                    :seed="item.Owner.id"
                    :size="24"
                  />
                  <v-icon v-else>mdi-account-question</v-icon>
                  <div>
                    <b>
                      {{ item.Owner.name || "Conta não criada" }}
                    </b>
                    <small class="d-block">
                      {{ item.Owner.phone }}
                    </small>
                  </div>
                </div>
              </template>
              <template v-slot:item.TicketBlock.name="{ item }">
                {{ item.TicketBlock.TicketGroup.name }}
                <small class="d-block">
                  {{ item.TicketBlock.name }}
                </small>
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
              </template>
            </v-data-table>
          </v-card>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="copyLinkModal" max-width="300">
      <v-card
        class="d-flex flex-column gap-2 pa-6 pb-2 justify-center align-center rounded-lg"
      >
        <!-- <v-icon x-large>mdi-shield-link-variant</v-icon> -->
        <h5 class="text-center">Link para emitir cortesias</h5>

        <qrcode-vue :value="link" :size="200"></qrcode-vue>
        <v-btn @click="copyLink" color="primary" text block>
          <v-icon left>mdi-{{ linkCopied ? "check" : "content-copy" }}</v-icon>
          {{ linkCopied ? "Link copiado" : "Copiar link" }}
        </v-btn>
        <v-alert color="info" class="mb-0" text dense>
          O link só pode ser acessado por responsáveis autorizados.
        </v-alert>
        <v-btn @click="copyLinkModal = false" text block small> Fechar </v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import COURTESY_EMITTER from "@/services/admin/courtesyEmitter";
import PAYMENT from "@/utils/payment";

import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PAYMENT,
      courtesyEmitters: [],
      loading: false,
      searchCourtesyEmitters: "",
      error: null,
      expanded: [],
      copyLinkModal: false,
      linkCopied: false,

      loadingCancel: false,
      errorCancel: false,

      loadingResend: false,
      errorResend: false,
      renotifyedCourtesy: [],

      headers: [
        { text: "Responsavel", value: "Responsible.name" },
        { text: "Emitidas", value: "emitted" },
        { text: "Lotes", value: "courtesyEmitters.length" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    async getCourtesyEmitters() {
      this.loading = true;
      try {
        const { courtesyEmitters } = await COURTESY_EMITTER.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.courtesyEmitters = courtesyEmitters;
      } catch (error) {
        this.error = error.message || "Erro ao buscar cortesias.";
      } finally {
        this.loading = false;
      }
    },

    copyLink() {
      const link = this.link;
      navigator.clipboard.writeText(link).then(() => {
        this.linkCopied = true;
        setTimeout(() => {
          this.linkCopied = false;
        }, 2000);
      });
    },
    async cancelCourtesy(courtesyEmitterId) {
      try {
        if (this.loadingCancel) return;
        this.loadingCancel = courtesyEmitterId;
        await COURTESY_EMITTER.cancel(
          this.party.organizationId,
          this.party.id,
          courtesyEmitterId
        );

        await this.getCourtesyEmitters();
      } catch (error) {
        console.log(error);
        this.errorCancel = false;
      } finally {
        this.loadingCancel = false;
      }
    },
    async renotifyCourtesy(responsibleId) {
      try {
        if (this.loadingResend) return;
        this.loadingResend = responsibleId;
        await COURTESY_EMITTER.notify(
          this.party.organizationId,
          this.party.id,
          responsibleId
        );

        this.renotifyedCourtesy.push(responsibleId);
      } catch (error) {
        console.log(error);
        this.errorResend = false;
      } finally {
        this.loadingResend = false;
      }
    },
  },
  computed: {
    courtesyEmittersByResponsible() {
      const reducedCourtesyEmitters = this.courtesyEmitters.reduce(
        (acc, item) => {
          const responsibleId = item.Responsible.id;
          if (!acc[responsibleId])
            acc[responsibleId] = {
              id: responsibleId,
              Responsible: item.Responsible,
              count: 0,
              emitted: 0,
              courtesyEmitters: [],
              Tickets: [],
            };

          acc[responsibleId].count += item.quantity;
          acc[responsibleId].emitted += item.Tickets.length;
          acc[responsibleId].courtesyEmitters.push(item);
          acc[responsibleId].Tickets.push(
            ...item.Tickets.map((t) => ({
              ...t,
              TicketBlock: item.TicketBlock,
            }))
          );

          return acc;
        },
        {}
      );
      const courtesyEmitters = Object.values(reducedCourtesyEmitters);
      if (!this.searchCourtesyEmitters) return courtesyEmitters;

      return courtesyEmitters.filter((courtesyEmitter) => {
        const search = (this.searchCourtesyEmitters || "").toLowerCase().trim();

        const phone = courtesyEmitter.Responsible.phone.replace(/\D/g, "");
        if (!isNaN(search)) return phone.includes(search);

        const name = (courtesyEmitter?.Responsible?.name || "").toLowerCase();
        return name?.includes(search);
      });
    },
    totalCourtesyTickets() {
      return this.courtesyEmitters.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
    },
    totalEmittedTickets() {
      return this.courtesyEmitters.reduce(
        (acc, item) => acc + item.Tickets.length,
        0
      );
    },
    totalPercentage() {
      return this.totalCourtesyTickets > 0
        ? (this.totalEmittedTickets / this.totalCourtesyTickets) * 100
        : 0;
    },
    link() {
      return `${window.location.origin}/staff/courtesy/${this.party.id}`;
    },
  },
  mounted() {
    this.getCourtesyEmitters();
    this.$root.$on("refresh-courtesy", this.getCourtesyEmitters);
  },
};
</script>

<style></style>
