<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="align-center">
      <h6 class="mb-0 flex-grow-1 lh-1">Calendário de vendas</h6>
      <v-btn icon @click="() => $refs.modal.open()" :disabled="loading" small>
        <v-icon small>mdi-arrow-expand</v-icon>
      </v-btn>
      <v-btn @click="type = type == 'day' ? 'month' : 'day'" icon small>
        <v-icon small>{{
          type == "day" ? "mdi-calendar-month" : "mdi-calendar"
        }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
      <template v-else>
        <div class="d-flex align-center">
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            text
            small
            class="ma-2"
            @click="focusToday"
            :disabled="todayIsVisible"
          >
            Hoje
          </v-btn>
          <v-spacer />
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-calendar
          :event-height="40"
          style="max-height: 450px"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @change="changeCalendar"
          @click:date="viewDay"
          @click:event="eventDetails"
        >
          <template v-slot:event="{ event }">
            <div class="ml-1">
              {{ event.total | currency }} <br />
              {{ event.quantity }} ingressos
            </div>
          </template>
        </v-calendar>
      </template>
    </v-card-text>
    <sales-by-day-modal ref="modal" :data="data" :loading="loading" />
  </v-card>
</template>

<script>
import moment from "moment";
import SalesByDayModal from "./modals/SalesByDayModal.vue";

export default {
  components: { SalesByDayModal },
  data: () => ({
    error: null,
    focus: null,
    start: null,
    end: null,
    type: "month",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    isIntersecting: false,
    viewed: false,
  }),
  methods: {
    focusToday() {
      this.value = moment().format("YYYY-MM-DD");
    },
    changeCalendar({ start, end }) {
      this.start = start;
      this.end = end;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = this.type == "day" ? "month" : "day";
    },
    eventDetails({ day }) {
      const date = moment(day.date).format("YYYY-MM-DD");
      this.$refs.modal.open(date);
    },
    getEventColor(event) {
      return event.color;
    },
    filterTickets({ start, end, dateFormat = "YYYY-MM-DD" }) {
      const min = moment(`${start.date}`).startOf("day").toDate();
      const max = moment(`${end.date}`).endOf("day").toDate();
      return this.data.tickets
        .filter(({ Payment: p }) => {
          const date = moment(p.payedAt || p.createdAt).toDate();
          return date >= min && date <= max && p.status === "succeeded";
        })
        .reduce((acc, t) => {
          const p = t.Payment;
          const date = moment(p.payedAt || p.createdAt)
          const key = date.format(dateFormat);
          if (!acc[key]) acc[key] = [];
          acc[key].push(t);
          return acc;
        }, {});
    },
    reduceTicketsToEvents({
      tickets,
      groupBy = "day",
      dateFormat = "YYYY-MM-DD",
    }) {
      var events = [];
      for (const [key, tckets] of Object.entries(tickets)) {
        let [quantity, total] = tckets.reduce(
          (acc, t) => {
            acc[0] += 1;
            acc[1] += t.amount - (t.platformFee || 0);
            return acc;
          },
          [0, 0]
        );
        events.push({
          start: moment(key, dateFormat).local().startOf(groupBy).toDate(),
          end: moment(key, dateFormat).local().endOf(groupBy).toDate(),
          color: "success",
          timed: groupBy === "hour",
          // tickets: tickets,
          quantity,
          total,
        });
      }
      return events;
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  computed: {
    events() {
      if (!this.start || !this.end) return [];
      const config = {
        format: this.type == "month" ? "YYYY-MM-DD" : "YYYY-MM-DD-HH",
        groupBy: this.type == "month" ? "day" : "hour",
      };

      const tickets = this.filterTickets({
        start: this.start,
        end: this.end,
        dateFormat: config.format,
      });
      const events = this.reduceTicketsToEvents({
        tickets,
        dateFormat: config.format,
        groupBy: config.groupBy,
      });
      return events;
    },
    todayIsVisible() {
      if (!this.value) return true;
      return moment(this.value).isSame(moment(), this.type);
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
