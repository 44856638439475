var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.complete)?[(_vm.loadingTicketGroups)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","text":""}},[_vm._v(" Carregando lotes, por favor aguarde... ")]):[_c('h5',[_vm._v("Link Promocional")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","outlined":"","disabled":_vm.loading,"dense":"","rules":[(v) => !!v || 'Campo obrigatório']},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-editor',{staticClass:"mb-4",attrs:{"label":"Descrição"},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.editMode)?_c('v-autocomplete',{attrs:{"items":_vm.ticketBlocksList,"item-text":"text","return-object":"","label":"Lote","dense":"","outlined":"","disabled":_vm.loading,"rules":[(v) => !!v || 'Campo obrigatório']},model:{value:(_vm.data.ticketBlock),callback:function ($$v) {_vm.$set(_vm.data, "ticketBlock", $$v)},expression:"data.ticketBlock"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Quantidade máxima de ingressos","hint":"O lote deve ter estoque suficiente para atender ao máximo de ingressos","type":"number","outlined":"","disabled":_vm.loading,"rules":[
                (v) => !!v || 'Campo obrigatório',
                (v) => v >= 1 || 'Mínimo de 1',
              ],"dense":""},model:{value:(_vm.data.maxTickets),callback:function ($$v) {_vm.$set(_vm.data, "maxTickets", $$v)},expression:"data.maxTickets"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Máximo de ingressos por usuário","type":"number","outlined":"","disabled":_vm.loading,"rules":[
                (v) => !!v || 'Campo obrigatório',
                (v) => v >= 1 || 'Mínimo de 1',
              ],"dense":""},model:{value:(_vm.data.maxPerUser),callback:function ($$v) {_vm.$set(_vm.data, "maxPerUser", $$v)},expression:"data.maxPerUser"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Data máxima de emissão","type":"datetime-local","outlined":"","disabled":_vm.loading,"dense":"","hint":"Deixe em branco para não limitar a data","min":new Date().toISOString().split('T')[0]},model:{value:(_vm.data.maxDate),callback:function ($$v) {_vm.$set(_vm.data, "maxDate", $$v)},expression:"data.maxDate"}})],1)],1)],1),(_vm.error)?_c('v-alert',{staticClass:"mb-0 mt-4",attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]]:[_c('div',{staticClass:"d-flex flex-column align-center"},[(!_vm.editMode)?_c('h5',{staticClass:"mb-2 text-center"},[_vm._v(" "+_vm._s(_vm.loading ? "Criando link promocional" : "Link promocional criado")+" ")]):_vm._e(),_c('div',{staticClass:"my-6"},[(_vm.loading && !_vm.editMode)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"50"}}):(_vm.courtesyPromotional && !_vm.editMode)?_c('v-btn',{attrs:{"color":"primary","large":"","disabled":_vm.copied || _vm.loading},on:{"click":_vm.copyLink}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(_vm.copied ? "check" : "content-copy"))]),_vm._v(" "+_vm._s(_vm.copied ? "Link copiado" : "Copiar link")+" ")],1):_vm._e()],1)]),(!_vm.editMode)?_c('v-btn',{attrs:{"block":"","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Fechar ")]):_vm._e()],(!_vm.complete)?_c('div',{staticClass:"d-flex justify-space-between mt-3"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.editMode ? _vm.$emit('close') : _vm.$emit('prev')}}},[_vm._v(" Voltar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid},on:{"click":_vm.send}},[_vm._v(" Continuar ")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }