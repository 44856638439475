<template>
  <v-card elevation="0" class="rounded">
    <div class="d-flex align-start gap-3">
      <v-icon
        :color="suggestion.acknowledge ? 'grey' : 'white'"
        class="rounded-circle pa-3"
        :class="suggestion.acknowledge ? 'grey lighten-2' : 'primary'"
      >
        {{ suggestion.icon }}
      </v-icon>
      <div class="d-flex flex-column w-full">
        <div class="d-flex align-center gap-2 justify-space-between">
          <div>
            <b class="mb-0 font-weight-bold">{{ suggestion.title }} </b>
            <i v-if="suggestion.type" class="text-caption ml-2">
              {{ types[suggestion.type] }}</i
            >
          </div>
          <v-chip
            x-small
            :color="suggestion.acknowledge ? 'grey' : 'warning'"
            label
          >
            -{{ suggestion.pointsLost }}
          </v-chip>
        </div>
        <p class="lh-5 text-13 mb-0">{{ suggestion.description }}</p>
      </div>
    </div>
    <div class="d-flex align-end justify-space-between mt-4">
      <v-alert
        v-if="suggestion.target"
        @click="$router.push(targetRoute)"
        small
        dense
        class="mb-0 py-1 text-13"
        style="cursor: pointer"
        text
        elevation="0"
        :color="suggestion.acknowledge ? 'grey' : 'primary'"
      >
        Gerenciar {{ targetLabel }}
      </v-alert>

      <v-btn
        elevation="0"
        x-small
        @click="$emit('acknowledge', suggestion.id)"
        text
      >
        {{ suggestion.acknowledge ? "Ignorado" : "Ignorar" }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SuggestionCard",
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
    partyId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    types: {
      SUGGESTION: "",
      SPELLCHECK: "Correção ortográfica",
      ERROR: "Informação errada",
      INCONSISTENCY: "Inconsistência",
      MISS_INFORMATION: "Informação faltante",
    },
  }),
  computed: {
    targetLabel() {
      const labels = {
        party: "Evento",
        ticketGroup: "Setor",
        ticketBlock: "Lote",
        attraction: "Atração",
      };
      return labels[this.suggestion.target] || "";
    },
    targetRoute() {
      const baseUrl = "/admin/organization/parties/";

      const routes = {
        party: `${baseUrl}${this.partyId}#details`,
        ticketGroup: `${baseUrl}${this.partyId}/management#tickets`,
        ticketBlock: `${baseUrl}${this.partyId}/management#tickets`,
        attraction: `${baseUrl}${this.partyId}#attractions`,
      };

      return routes[this.suggestion.target] || "";
    },
  },
};
</script>

<style scoped></style>
